<template>
  <Sidebar
    v-model:visible="displayModal"
    position="bottom"
    :showCloseIcon="false"
    :dismissable="false"
    class="p-sidebar-lg"
  >
    <div class="lg:px-3">

      <div class="grid grid-cols-1 md:grid-cols-3 text-xxs gap-0 md:gap-5">
        <div class="flex flex-col">
          <strong>Lote</strong>
          {{ infoMedicamento.labelLote }}
          <MessageError class="text-xs" :text="erroresNov.loteNum"/>
        </div>
        <div class="flex flex-col">
          <strong>Medicamento:</strong>
          <span>{{ infoMedicamento.nCompleto }}</span>
        </div>
        <div class="flex flex-col" v-if="infoMedicamento.U_PHR_ItemSustituto">
          <strong>Medicamento Sustituto: </strong>
          <span>{{ infoMedicamento.ItemCode + ' - ' + infoMedicamento.U_PHR_ItemSustituto }}</span>
        </div>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-3 text-xxs gap-0 md:gap-5 my-2">
        <div class="flex flex-col col-span-1">
          <strong>Tipo de novedad</strong>
          <Dropdown
            v-model="createInfo.tipo"
            :options="novedadesTypes"
            size="small"
            class="rounded-xs border-gray-300 w-full text-xs p-inputtext-xs"
            panelClass="text-xxs"
            inputClass="p-inputtext-xxs text-xxs"
            optionLabel="nombre"
            :showClear="true"
            optionValue="id"
            placeholder="Seleccione un tipo de novedad..."
          />
          <MessageError class="text-xs" :text="erroresNov.tipo"/>
        </div>
        <div class="flex flex-col col-span-2">
          <strong>Detalle de novedad</strong>
          <Dropdown
            v-model="createInfo.detalleNov"
            :options="novedadesDetalle"
            size="small"
            class="rounded-xs border-gray-300 w-full text-xs p-inputtext-xs"
            panelClass="text-xxs"
            inputClass="p-inputtext-xxs text-xxs"
            :optionLabel="formatLabelDetalleNov"
            :showClear="true"
            optionValue="codigo"
            placeholder="Seleccione un detalle de novedad..."
          />
          <MessageError class="text-xs" :text="erroresNov.detalleNov"/>
        </div>
      </div>
      <div class="my-2 text-xxs">
        <div class="flex flex-col">
          <strong>Observación</strong>
          <Textarea v-model="createInfo.observacion" rows="2" cols="30" class="p-inputtext-xxs text-xxs"/>
          <MessageError class="text-xs" :text="erroresNov.observacion"/>
        </div>
      </div>
      <div class="flex justify-between">
        <span class="text-md">Listado de novedades</span>
      </div>
      <div>
        <DataTable
          :value="novedades"
          class="p-datatable-sm text-xxs"
          showGridlines
          dataKey="codigo"
          responsiveLayout="scroll"
          :scrollable="true"
          scrollHeight="190px"
        >
          <template #empty>
            No hay novedades registradas
          </template>
          <template #header>
            <div class="flex justify-end">
              <Button icon="pi pi-plus" label="Agregar"
                      class="p-button-outlined p-button-success text-xs xl:text-md p-2"
                      @click="addNovedad"/>
            </div>
          </template>
          <!-- <Column field="" header="Medicamento" headerStyle="text-align: center;justify-content: center;" style="min-width:10rem">
                        <template #body="{data}">
                          <div>{{ data.U_PHR_ItemCodSolicitado }} - {{ data.ItemName }}</div>
                        </template>
                      </Column>-->
          <!--        <Column field="DistNumber" header="Lote" headerStyle="text-align: center;justify-content: center;"
                          style="min-width:10rem;max-width:10rem">
                    <template #body="{data}">
                      <div class="flex flex-col">
                        <div>
                          <strong class="mr-1">Lote:</strong>
                          <span>{{ data.DistNumber }}</span>
                        </div>
                        <div>
                          <strong class="mr-1">Fec. Vencimiento:</strong>
                          <span>{{ data.ExpDate }}</span>
                        </div>
                        <div>
                          <strong class="mr-1">Ubicación:</strong>
                          <span>{{ data.SL1Abs === 39 ? 'Devoluciones' : 'PendientesOV' }}</span>
                        </div>
                      </div>
                    </template>
                  </Column>-->
          <Column field="nombreTipo" header="Tipo Novedad" headerStyle="text-align: center;justify-content: center;"
                  style="min-width:10rem"/>
          <Column field="detalleNov" header="Detalle" headerStyle="text-align: center;justify-content: center;"
                  style="min-width:15rem">
            <template #body="{data}">
              {{ data.detalleNov ? `${data.detalleNov} - ${data.nombreDetalleNov}` : '' }}
            </template>
          </Column>
          <Column field="observacion" header="Observación" headerStyle="text-align: center;justify-content: center;"
                  bodyStyle="word-break: break-all"
                  style="min-width:15rem"/>
          <Column field="" header="Acciones" headerStyle="text-align: center;justify-content: center;"
                  bodyStyle="text-align: center;justify-content: center;" style="min-width:6rem">
            <template #body="slotProps">
              <Button v-tooltip.top="'Editar novedad'"
                      class="p-button-text p-button-warning p-0" @click="editNovedad(slotProps)"
              >
                <editIcon class="w-2/3"/>
              </Button>
              <Button v-tooltip.top="'Eliminar novedad'"
                      class="p-button-text p-button-danger p-0" @click="eliminarNovedad(slotProps)"
              >
                <trashIcon class="w-2/3"/>
              </Button>
            </template>
          </Column>
        </DataTable>
      </div>
      <div class="flex justify-center md:justify-end mt-2">
        <Button label="Confirmar novedades"
                icon="pi pi-check"
                class="p-button-rounded p-button-success text-xs xl:text-md" @click="confirmNovedad"
        />
      </div>
    </div>
  </Sidebar>
</template>

<script>
import { computed, ref, watch } from 'vue'
import recepcionPedidos from '@/apps/pharmasan/compras/logistica/store/recepcionPedidos'
import RecepcionPedidosService from '@/apps/pharmasan/compras/logistica/services/rcp-pedidos.service'
import * as yup from 'yup'
import Swal from 'sweetalert2'
import { useForm } from 'vee-validate'

export default {
  name: 'modalRegistrarNovedades',
  props: {
    isEdit: {
      type: Boolean,
      default: false,
      required: true
    }
  },
  emits: ['updateNovedades'],
  setup (props, { emit }) {
    const displayModal = ref(false)
    const _RPedidosService = new RecepcionPedidosService()
    const storeLotes = computed(() => recepcionPedidos.getters._lotes)
    const novedades = ref([])
    const infoMedicamento = ref({})
    const schemaNovedades = yup.object({
      loteNum: yup.string('').required('El lote es requerido').label('lote'),
      tipo: yup.string('').required('El tipo de novedad es requerido').label('tipo de novedad'),
      detalleNov: yup.string('').nullable().label('detalle de la novedad'),
      observacion: yup.string('').nullable().label('observación'),
      novId: yup.number().nullable().label('id de novedad')
    })
    // useField('loteNum')
    // useField('tipo')
    // useField('detalleNov')
    // useField('observacion')
    const { errors: erroresNov, values: createInfo, handleSubmit, handleReset } = useForm({
      validationSchema: schemaNovedades
    })
    const novedadesTypes = ref([])
    const novedadesDetalle = ref([])
    const openModal = async (data) => {
      handleReset()
      infoMedicamento.value = data.medicamento
      createInfo.loteNum = `${data.lote.DistNumber}||${data.lote.Quantity}||${data.lote.ExpDate}||${data.lote.AbsEntry}||${data.lote.SL1Abs}`
      novedades.value = data.lote.novedades
      await getNovedadesTypes()
      displayModal.value = true
    }

    const formatLabelDetalleNov = (option) => {
      return `${option.codigo} - ${option.nombres}`
    }
    const addNovedad = handleSubmit(async (values) => {
      if (!values) return
      let validate = parseInt(values.tipo) === 6 && parseInt(createInfo.loteNum.split('||')[4]) === 38 // tipo de novedad (6) "defecto crítico" y código de la ubicación (38) "PENDIENTESOV"
      if (validate) {
        await Swal.fire({
          icon: 'info',
          title: 'Información',
          html: 'Se agregó un tipo de novedad: <strong>"Defecto crítico"</strong><br>Este lote se encuentra en la ubicación de <strong>PENDIENTESOV</strong>',
          showConfirmButton: true,
          showCancelButton: true,
          focusCancel: true,
          confirmButtonText: 'Aceptar',
          cancelButtonText: 'No, cancelar'
        }).then((resp) => {
          validate = resp.isConfirmed
        })
      } else {
        validate = true
      }
      /*
      * DistNumber pos 0
      * Quantity pos 1
      * ExpDate pos 2
      * AbsEntry pos 3
      * SL1Abs pos 4
      */
      if (validate) {
        const loteInfo = values.loteNum.split('||')
        const duplicado = novedades.value.some(nov =>
          `${nov.LineNum}${nov.U_PHR_ItemCodSolicitado}${nov.DocNum}${nov.DistNumber}${nov.AbsEntry}${nov.tipo}${nov.detalleNov}` ===
          `${infoMedicamento.value.LineNum}${infoMedicamento.value.U_PHR_ItemCodSolicitado}${infoMedicamento.value.DocNum}${loteInfo[0]}${loteInfo[3]}${values.tipo}${![undefined, ''].includes(values.detalleNov) ? values.detalleNov : null}`)
        if (duplicado) {
          Swal.fire({
            icon: 'warning',
            title: 'Advertencia',
            text: 'El medicamento y lote ya poseen la novedad registrada',
            showConfirmButton: true,
            confirmButtonText: 'OK'
          })
          return
        }

        const tipoInfo = novedadesTypes.value.find((x) => x.id === values.tipo)
        const detalleNovInfo = novedadesDetalle.value.find((x) => x.codigo === values.detalleNov)
        const payload = {
          ItemCode: infoMedicamento.value.ItemCode,
          U_PHR_ItemCodSolicitado: infoMedicamento.value.U_PHR_ItemCodSolicitado,
          U_PHR_ItemSustituto: infoMedicamento.value.U_PHR_ItemSustituto,
          ItemName: infoMedicamento.value.Dscription,
          LineNum: infoMedicamento.value.LineNum,
          DocNum: infoMedicamento.value.DocNum,
          DocEntry: infoMedicamento.value.DocEntry,
          DistNumber: loteInfo[0],
          Quantity: parseInt(loteInfo[1]),
          ExpDate: loteInfo[2],
          AbsEntry: parseInt(loteInfo[3]),
          SL1Abs: parseInt(loteInfo[4]),
          nombreTipo: tipoInfo.nombre,
          nombreDetalleNov: detalleNovInfo !== undefined ? detalleNovInfo.nombres : '',
          tipo: values.tipo,
          detalleNov: values.detalleNov,
          observacion: values.observacion,
          novId: values.novId ? values.novId : null
        }
        if (!props.isEdit) {
          recepcionPedidos.commit('addNovedad', payload)
        } else {
          novedades.value.push(payload)
        }
        createInfo.tipo = ''
        createInfo.detalleNov = ''
        createInfo.observacion = ''
      }
    })

    const editNovedad = ({ index, data }) => {
      createInfo.loteNum = `${data.DistNumber}||${data.Quantity}||${data.ExpDate}||${data.AbsEntry}||${data.SL1Abs}`
      createInfo.tipo = data.tipo
      createInfo.detalleNov = data.detalleNov ? data.detalleNov : null
      createInfo.observacion = data.observacion
      createInfo.novId = data.novId ? data.novId : null
      if (!props.isEdit) {
        const indexStore = storeLotes.value.findIndex((a) => `${a.U_PHR_ItemCodSolicitado}${a.LineNum}${a.BaseRef}${a.lote}${a.AbsEntry}${a.SL1Abs}` === `${data.U_PHR_ItemCodSolicitado}${data.LineNum}${data.DocNum}${data.DistNumber}${a.AbsEntry}${a.SL1Abs}`)
        const indexNovedad = storeLotes.value[indexStore].novedades.findIndex(a => `${a.DistNumber}${a.AbsEntry}${a.SL1Abs}${a.tipo}${a.detalleNov}` === `${data.DistNumber}${data.AbsEntry}${data.SL1Abs}${data.tipo}${data.detalleNov}`)
        recepcionPedidos.commit('deleteNovedad', { indexStore, indexNovedad })
      } else {
        novedades.value.splice(index, 1)
      }
    }
    const eliminarNovedad = ({ index, data }) => {
      Swal.fire({
        icon: 'question',
        title: '¿Esta seguro?',
        text: '¿Desea eliminar la novedad?',
        showConfirmButton: true,
        showCancelButton: true,
        focusCancel: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No, cancelar'
      }).then(async ({ value }) => {
        if (value) {
          if (!props.isEdit) {
            const indexStore = storeLotes.value.findIndex((a) => `${a.U_PHR_ItemCodSolicitado}${a.LineNum}${a.BaseRef}${a.lote}${a.AbsEntry}${a.SL1Abs}` === `${data.U_PHR_ItemCodSolicitado}${data.LineNum}${data.DocNum}${data.DistNumber}${a.AbsEntry}${a.SL1Abs}`)
            const indexNovedad = storeLotes.value[indexStore].novedades.findIndex(a => `${a.DistNumber}${a.AbsEntry}${a.SL1Abs}${a.tipo}${a.detalleNov}` === `${data.DistNumber}${data.AbsEntry}${data.SL1Abs}${data.tipo}${data.detalleNov}`)
            recepcionPedidos.commit('deleteNovedad', { indexStore, indexNovedad })
          } else {
            if (data.novId) {
              await _RPedidosService.deleteNovedad(data.novId).then(({ data }) => {
                if (data.affected) {
                  Swal.fire({
                    icon: 'success',
                    title: 'Novedad borrada',
                    text: 'novedad borrada con éxito',
                    timer: 1500,
                    timerProgressBar: true,
                    showConfirmButton: false
                  })
                }
              })
            }
            novedades.value.splice(index, 1)
          }
        }
      })
    }
    const getNovedadesTypes = async () => {
      return _RPedidosService.getTiposNovedades().then(({ data }) => {
        novedadesTypes.value = data
      })
    }
    const searchDetalleNovedad = async () => {
      if (createInfo.tipo) {
        createInfo.detalleNov = ''
        return _RPedidosService.getDetallesNovedades(createInfo.tipo).then(({ data }) => {
          novedadesDetalle.value = data
        })
      }
    }
    const confirmNovedad = () => {
      if (props.isEdit) {
        emit('updateNovedades', { nove: novedades.value, infoLote: createInfo.loteNum.split('||') })
      }
      displayModal.value = false
    }
    watch(() => createInfo.tipo, (a) => {
      searchDetalleNovedad()
    })
    return {
      displayModal,
      infoMedicamento,
      createInfo,
      novedadesTypes,
      novedadesDetalle,
      novedades,
      erroresNov,
      openModal,
      searchDetalleNovedad,
      editNovedad,
      eliminarNovedad,
      confirmNovedad,
      formatLabelDetalleNov,
      addNovedad
    }
  }
}
</script>

<style scoped>
::v-deep(.p-card-content) {
  padding: 0rem;
}

:global(.swal2-container) {
  z-index: 1000000 !important
}

::v-deep(.p-sidebar .p-sidebar-header) {
  padding: 0;
}

::v-deep(.p-dropdown-item) {
  word-break: break-all;
}
</style>
